.cursor-default {
  cursor: default;
}

@include media-breakpoint-down(sm) {
  .btn-download-social-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn-download-social {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.btn-download-social {
  padding: 8px;
  display: flex;
  min-width: 180px;
  width: 180px;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  background-color: $anatomy-dark-light;
  transition: background-color 0.25s linear;
  height: 50px;

  &:hover {
    outline: 0;
    background-color: $anatomy-dark-extra-light;
    color: #fff;
    text-decoration: none;
  }

  img {
    height: 34px;
    width: 34px;
  }

  div {
    padding-left: 10px;

    .top-text {
      font-size: 10px;
      font-weight: 400
    }

    .bottom-text {
      font-size: 16px;
      text-transform: capitalize;
      margin-top: -4px;
      font-weight: 600;
    }
  }

  &.disabled {
    cursor: default;
    background-color: $anatomy-dark-extra-light;
  }
}

.bg-anatomy-dark-extra-light {
  background: $anatomy-dark-extra-light;
}
